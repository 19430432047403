html {
    background-color: #f5f7fa;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiTable-root .MuiTableCell-body {
  color: black;
}

.dashboard__country-select-wrapper {
  max-width: 400px;
  padding: 0 24px;
  width: 100%;
}

.dashboard__country-select-wrapper > .country-select {
  display: flex;
  margin: 0 0  12px 0;
}